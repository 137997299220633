import React, { ComponentPropsWithoutRef, forwardRef } from 'react'

import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { Link } from 'react-router-dom'

export interface RouterLinkProps extends ComponentPropsWithoutRef<typeof Link> {
  black?: boolean
}

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ to, children, black, ...props }, ref) => {
    return (
      <StyledRouterLink
        ref={ref}
        to={to}
        className={black ? blackCss : blueCss}
        {...props}
      >
        {children}
      </StyledRouterLink>
    )
  }
)

const StyledRouterLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`
const blueCss = css`
  color: var(--info-soft, #90aefd);

  &:visited {
    color: var(--info-soft, #90aefd);
  }
`
const blackCss = css`
  color: var(--accent-primary-default, #2e2a29);

  &:visited {
    color: var(--accent-primary-default, #2e2a29);
  }
`
