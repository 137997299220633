/* eslint-disable max-lines */
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { VoidHandler } from 'common/types'
import { isScrolledToBottom } from 'components/page/Chat/Messenger/function/isScrolledToBottom'
import { zIndex } from 'components/presentational/zIndex'
import { fullStopPropagation } from 'functions/fullStopPropagation'

import { BOTTOM_SHEET_KEY, BOTTOM_SHEET_VALUE } from './BottomSheet.constants'
import { BottomSheetTitle } from './BottomSheetTitle'
import { useAnimateContentHeight } from './hooks/useAnimateContentHeight'
import { Loader } from '../Loader/Loader'
import { breakpoints } from '../shared/breakpoints'
import { applyIfAnimationsEnabledCss } from '../styles/applyIfAnimationsEnabledCss'

export const BottomSheetInner: FC<{
  title?: ReactNode
  subtitle?: ReactNode
  data: unknown
  open: boolean | null
  setOpen: (open: boolean) => void
  onUnmounted: VoidHandler
  /**
   * Чтобы список не сжимался по высоте.
   * Например при фильтрации списка высота контейнера будет уменьшаться.
   */
  noHeightShrink?: boolean
  /** Если нужно расположить контент по-центру на десктопе */
  centerContentVerticallyOnDesktop?: boolean
  mobile: boolean | null
  isIOS: boolean
  children:
    | ReactNode
    | ((params: {
        data: unknown
        contentNode: HTMLDivElement | null
      }) => ReactNode)
  animationsDisabled?: boolean | null
  dataName?: string
}> = ({
  title,
  subtitle,
  data,
  children,
  open,
  setOpen,
  onUnmounted,
  noHeightShrink,
  centerContentVerticallyOnDesktop,
  mobile,
  isIOS,
  animationsDisabled,
  dataName,
}) => {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [contentNode, setContentNode] = useState<HTMLDivElement | null>(null)
  const contentInnerRef = useRef<HTMLDivElement | null>(null)
  const movingRef = useRef<HTMLDivElement | null>(null)
  const domRect = useRef<DOMRect | undefined>()
  const positionYRef = useRef<number>(0)
  const scrollTopRef = useRef<number>(0)
  const startingScrollTopRef = useRef<number>(0)
  const offsetRef = useRef<number>(0)
  const isDraggingRef = useRef<boolean>(false)
  const isScrolledRef = useRef<boolean>(false)
  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    if (open === null) {
      return
    }

    if (open && !mounted) {
      setMounted(true)
      return
    }

    requestAnimationFrame(() => {
      if (!movingRef.current || !overlayRef.current) {
        return
      }

      if (open) {
        domRect.current = movingRef.current.getBoundingClientRect()
        overlayRef.current.style.opacity = OPACITY_SHOW
        movingRef.current.style.transform = TRANSFORM_SHOW
      } else {
        overlayRef.current.style.opacity = OPACITY_HIDE
        movingRef.current.style.transform = TRANSFORM_HIDE

        // Закрываем с помощью таймаута, потому что на iOS не вызывается событие transitionend
        window.setTimeout(() => {
          setMounted(false)
          requestAnimationFrame(() => onUnmounted(true))
        }, ANIMATION_DURATION_MS)
      }
    })
  }, [open, mounted, onUnmounted])

  // Установим аттрибут на документ, чтобы можно было установить на него стили
  useEffect(() => {
    if (mounted) {
      setAttributeToDocument()
    } else {
      removeAttributeFromDocument()
    }
    return () => {
      removeAttributeFromDocument()
    }
  }, [mounted, onUnmounted])

  useEffect(() => {
    if (!movingRef.current || !overlayRef.current || !contentRef.current) {
      return
    }

    const setOverlayColor = (positionY: number) => {
      if (!domRect.current || !overlayRef.current) {
        return
      }
      const shiftPercent = positionY / domRect.current.height
      const opacity = 1 - shiftPercent - 0.6
      overlayRef.current.style.opacity = String(opacity)
    }

    const handleTouchStart = (event: TouchEvent) => {
      if (!movingRef.current || !contentRef.current || !domRect.current) {
        return
      }
      if (!animationsDisabled) {
        movingRef.current.style.transition = TRANSITION_MOVE
      }
      domRect.current = movingRef.current.getBoundingClientRect()
      isDraggingRef.current = true
      const clientY = event.touches[0].clientY
      offsetRef.current = clientY - domRect.current.top
      isScrolledRef.current = contentRef.current.scrollTop !== 0
      startingScrollTopRef.current = scrollTopRef.current
    }

    const handleTouchMove = (event: TouchEvent) => {
      if (!contentRef.current || !movingRef.current) {
        return
      }

      const clientY = event.touches[0].clientY
      const positionY = Math.round(clientY - offsetRef.current)

      const overscrollUp = contentRef.current.scrollTop === 0 && positionY > 0
      const overscrollDown =
        isScrolledToBottom(contentRef.current) && positionY < 0

      if (isIOS && (overscrollUp || overscrollDown)) {
        contentRef.current.style.overflowY = 'hidden'
        // Чтобы не срабатывал pull to refresh на iOS 15 и 16
        // И чтобы нельзя было оттянуть контент вверх, что приводит к багу со скроллом
        // https://youtrack.mamba.ru/issue/M-8320
        event.preventDefault()
      }

      const scrollingContent =
        isScrolledRef.current &&
        contentRef.current.contains(event.target as HTMLElement)

      if (
        !isDraggingRef.current ||
        scrollingContent /** Если это скролл контента - не перемещаем */ ||
        positionY < 0 /** Не даем скроллить выше дозволенного. */ ||
        contentRef.current.scrollTop < 0 /** Отрицательный скролл iOS */
      ) {
        return
      }

      setOverlayColor(positionY)
      positionYRef.current = positionY
      if (!animationsDisabled) {
        movingRef.current.style.transition = TRANSITION_MOVE
      }
      movingRef.current.style.transform = `translate3d(0, ${positionY}px, 0)`
    }

    const handleTouchEnd = (event: TouchEvent) => {
      if (!contentRef.current || !movingRef.current) {
        return
      }
      contentRef.current.style.overflowY = 'scroll'
      if (!animationsDisabled) {
        movingRef.current.style.transition = TRANSITION_START
      }

      const exception = contentRef.current.contains(event.target as HTMLElement)

      // Кейс для iOS: пользователь активно оттягивает прокручивающийся список вниз
      if (
        startingScrollTopRef.current < 0 &&
        scrollTopRef.current < 0 &&
        scrollTopRef.current < startingScrollTopRef.current
      ) {
        setOpen(false)
        return
      }

      if (scrollTopRef.current < -50) {
        setOpen(false)
        return
      }

      if (!isDraggingRef.current || (isScrolledRef.current && exception)) {
        isScrolledRef.current = false
        return
      }

      if (!domRect.current || !movingRef.current) {
        return
      }
      const clientY = event.changedTouches[0].clientY
      const positionY = clientY - offsetRef.current
      const height = domRect.current.height
      const dragged = positionY > height / 5
      const offsetY = dragged ? height : 0
      setOverlayColor(offsetY)

      if (dragged) {
        setOpen(false)
      } else {
        movingRef.current.style.transform = TRANSFORM_SHOW
      }

      isDraggingRef.current = false
    }

    movingRef.current.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove, { passive: false })
    document.addEventListener('touchend', handleTouchEnd)

    const moving = movingRef.current

    return () => {
      moving.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [animationsDisabled, isIOS, mounted, setOpen])

  useAnimateContentHeight(
    contentNode,
    contentInnerRef.current,
    noHeightShrink,
    mobile
  )

  if (!mounted) {
    return
  }

  const handleCloseClick = () => {
    setOpen(false)
  }
  const handleOverlayClick = () => {
    setOpen(false)
  }

  const handleContentScroll = (event) => {
    if (!titleRef.current) {
      return
    }

    scrollTopRef.current = event.target.scrollTop

    titleRef.current.style.boxShadow =
      event.target.scrollTop > 0 ? TITLE_BOX_SHADOW : 'none'
  }

  /**
   * На десктопе заголовок показываем всегда, потому что там есть крестик закрытия, даже если в заголовке нет текста.
   * Дизайнеры не придумали ничего лучше.
   * На мобилке можно скрыть заголовок, если текста в нем нет. Закрыть боттом шит можно свайпом вниз.
   */
  const titleContainerVisible = mobile ? Boolean(title || subtitle) : true

  return (
    <BottomSheet data-name={dataName}>
      <Overlay ref={overlayRef} />

      <Moving ref={movingRef} onClick={handleOverlayClick}>
        <Container
          ref={containerRef}
          onClick={fullStopPropagation}
          className={cx(
            centerContentVerticallyOnDesktop &&
              centerContentVerticallyOnDesktopCss
          )}
        >
          <Handle />

          {titleContainerVisible && (
            <TitleContainer ref={titleRef}>
              <BottomSheetTitle title={title} onCloseClick={handleCloseClick} />
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </TitleContainer>
          )}

          <Content
            ref={(node) => {
              // Нужно стандартных целей и для оптимизации
              contentRef.current = node
              // Нужно, чтобы случился еще один рендер
              setContentNode(node)
            }}
            onScroll={handleContentScroll}
          >
            <ContentInner ref={contentInnerRef}>
              {(typeof children === 'function'
                ? children({ data, contentNode })
                : children) || <Loader />}
            </ContentInner>
          </Content>
        </Container>
      </Moving>
    </BottomSheet>
  )
}

const hasAttributeOnDocument = () => {
  return document.documentElement.hasAttribute(BOTTOM_SHEET_KEY)
}
const setAttributeToDocument = () => {
  if (!hasAttributeOnDocument()) {
    document.documentElement.setAttribute(BOTTOM_SHEET_KEY, BOTTOM_SHEET_VALUE)
  }
}
const removeAttributeFromDocument = () => {
  if (hasAttributeOnDocument()) {
    document.documentElement.removeAttribute(BOTTOM_SHEET_KEY)
  }
}

const ANIMATION_DURATION_MS = 300
const TRANSITION_START = `transform ${ANIMATION_DURATION_MS}ms ease-out`
const TRANSITION_MOVE = `transform ${30}ms linear`
const TITLE_BOX_SHADOW = '0px -1px 0px 0px rgba(127, 116, 114, 0.16) inset'
const TRANSFORM_SHOW = 'translate3d(0, 0, 0)'
const TRANSFORM_HIDE = 'translate3d(0, 100%, 0)'
const OPACITY_SHOW = '0.4'
const OPACITY_HIDE = '0'

const BottomSheet = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.base};
`
const Handle = styled.div`
  position: absolute;
  top: -12px;
  width: 48px;
  height: 4px;
  border-radius: 2.5px;
  opacity: 0.48;
  background: var(--warm-hard, #2e2a29);
  background-blend-mode: overlay;

  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: none;
  }
`
const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  touch-action: none;
  opacity: ${OPACITY_HIDE};
  ${applyIfAnimationsEnabledCss(`
    transition: opacity ${ANIMATION_DURATION_MS}ms ease-out;
    will-change: opacity;
  `)};
  background-color: black;
`
const Moving = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  touch-action: none;
  transform: ${TRANSFORM_HIDE};
  ${applyIfAnimationsEnabledCss(`
    will-change: transform;
    transition: ${TRANSITION_START};
  `)};
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  background: white;
  border-radius: 32px 32px 0px 0px;
  ${applyIfAnimationsEnabledCss(`
    transition: box-shadow ${ANIMATION_DURATION_MS}ms;
    will-change: box-shadow;
  `)};
  padding: var(--spacing-32px, 32px) var(--spacing-16px, 16px)
    var(--spacing-12px, 12px) var(--spacing-16px, 16px);

  @media screen and (min-width: ${breakpoints.mobile}px) {
    padding: var(--spacing-40px, 40px) var(--spacing-40px, 40px)
      var(--spacing-16px, 16px) var(--spacing-40px, 40px);
  }
`
const Subtitle = styled.div`
  margin-top: var(--spacing-24px, 24px);
`
const Container = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 90%;
  border-radius: 32px 32px 0px 0px;
  background: var(--background-surface-1, #fff);

  @media screen and (min-width: ${breakpoints.mobile}px) {
    min-height: 100%;
    height: 100%;
  }
`
const Content = styled.div`
  width: 100%;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  ${applyIfAnimationsEnabledCss(`
    transition: all 500ms ease-in-out;
  `)};
  border-radius: 32px 32px 0px 0px;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: ${breakpoints.mobile}px) {
    height: 100%;
  }
`
const ContentInner = styled.div`
  padding: var(--spacing-24px, 24px);

  @media screen and (min-width: ${breakpoints.mobile}px) {
    padding: var(--spacing-24px, 24px) var(--spacing-40px, 40px) 0px
      var(--spacing-40px, 40px);
  }
`
const centerContentVerticallyOnDesktopCss = css`
  @media screen and (min-width: ${breakpoints.mobile}px) {
    ${TitleContainer} {
      position: absolute;
      z-index: 1;
      background: transparent;
    }
    ${Content}, ${ContentInner} {
      height: 100%;
    }
  }
`
